"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onClientEntry = exports.wrapRootElement = void 0;
const tslib_1 = require("tslib");
const React = tslib_1.__importStar(require("react"));
const gatsby_source_prismic_1 = require("gatsby-source-prismic");
const wrapRootElement = (args) => {
    return React.createElement(gatsby_source_prismic_1.PreviewStoreProvider, null,
        " ",
        args.element,
        " ");
};
exports.wrapRootElement = wrapRootElement;
const onClientEntry = () => {
    window.addEventListener('load', () => {
        document.body.classList.remove('no-js', 'ssr');
        document.body.classList.add('js', 'client');
    });
};
exports.onClientEntry = onClientEntry;
